


// 消费数据总览
function Statistics(props) {
    const statics = props.val;
    function Days() {
        return (
            <div className="Statistics">
                <div className="stat_item">
                    <div className="stat_item_box">
                        <span className="box_span">累计消费总量（元）</span>
                        <span>￥{statics.days.consumeTotal}</span>
                    </div>
                </div>
                <div className="stat_item">
                    <div className="stat_item_box">
                        <span className="box_span">累计使用次数</span>
                        <span>{statics.days.useTotal}</span>
                    </div>
                </div>
                <div className="stat_item">
                    <div className="stat_item_box">
                        <span className="box_span">余额（元）</span>
                        <span>￥{statics.days.balance}</span>
                    </div>
                </div>
            </div>
        )
    }
    function Day() {
        return (
            <div className="Statistics">
                <div className="stat_item">
                    <div className="stat_item_box">
                        <span className="box_span">近7日消费总量（元）</span>
                        <span>￥{statics.day.days7Amount}</span>
                    </div>
                </div>
                <div className="stat_item">
                    <div className="stat_item_box">
                        <span className="box_span">当日消费总量（元）</span>
                        <span>￥{statics.day.dayAmount}</span>
                    </div>
                </div>
                <div className="stat_item">
                    <div className="stat_item_box">
                        <span className="box_span">近7天使用次数（次）</span>
                        <span>{statics.day.days7Use}</span>
                    </div>
                </div>
                <div className="stat_item">
                    <div className="stat_item_box">
                        <span className="box_span">当日使用次数（次）</span>
                        <span>{statics.day.dayUse}</span>
                    </div>
                </div>
                <div className="stat_item">
                    <div className="stat_item_box">
                        <span className="box_span">余额（元）</span>
                        <span>￥{statics.day.balance}</span>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <>
        { statics.days != null ? <Days/> : <Day/>}
        </>

    )
}
export default Statistics;