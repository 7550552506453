/**t通知信息 */
import request from "../request/api";

export const getSettingInfo = () => {
  return request({
    method: "get",
    url: "/api/v3/setting",
  });
};

export const updateSettingInfo = (data) => {
  return request({
    method: "put",
    url: "/api/v3/setting",
    data,
  });
};
