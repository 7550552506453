import request from "../request/api";

/**注册 */
export const register = (data) => {
  return request({
    method: "post",
    url: "/api/v3/user",
    data: data,
  });
};

/**
 * 登录
 * @param {*} data
 * @returns
 */
export const login = (data) => {
  return request({
    method: "post",
    url: "/api/v3/auth",
    data: data,
  });
};
/**
 * 发送重置密码邮件
 * @param {*} data
 * @returns
 */
export const sendResetPasswordEmail = (mail) => {
  return request({
    method: "get",
    url: "/api/v3/user/sendResetPasswordEmail",
    params: {
      mail,
    },
  });
};
/**
 *  重置密码
 * @param {*} data
 * @returns
 */
export const resetPassword = (data) => {
  return request({
    method: "put",
    url: "/api/v3/user/resetPassword",
    data,
  });
};
