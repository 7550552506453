import React, { useState } from 'react';
import '../../assets/home.css'
import instance from '../../request/api';
import { Button, InputNumber, Form, Input, message, Table, Space } from 'antd';




const View = () => {
    // 生成的数据
    const [dataSource, setdataSource] = useState([]);
    // 能否点击
    const [loading,setLoading] = useState(false);
    // 完成数量
    const [num, setNum] = useState(0)
    // 继续生成
    const [btn, setbtn] = useState(0)
    const columns = [
        {
            title: '用户名',
            dataIndex: 'userName',
            align: 'center',
            key: 'userName',
        },
        {
            title: '密码',
            dataIndex: 'password',
            align: 'center',
            key: 'password',
        },
        {
            title: 'token',
            dataIndex: 'token',
            align: 'center',
            key: 'token',
        },
        {
            title: '金额',
            dataIndex: 'amount',
            align: 'center',
            key: 'amount',
        },
        {
            title: '状态',
            dataIndex: 'state',
            key: 'state',
            width: '220px',
            align: 'center',
            render: (_, record) => (
                <>
                    {record.state == 0 && <span>等待创建</span>}
                    {record.state == 1 && <span style={{ color: '#03c7ab' }}>已创建</span>}
                    {record.state == 2 && <span style={{ color: 'red' }}>{record.desc}</span>}
                </>
            ),
        },
    ];

    const onFinish = (values) => {
        console.log('Success:', values);
        if (values.end <= values.astart) {
            message.error('结束编号不能小于起始编号')
            return;
        }
        // 初始化数量
        setNum(0);
        setbtn(0);
        // 初始化数据
        setdataSource([]);
        // 设置生成账号
        setAccount(values);
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    // 继续创建
    const continues = async () => {
        setLoading(true)
        let users = [...dataSource];
        let i = num <= 0 ? 0 : num;
        let len = users.length;
        for (i; i < users.length; i++) {
            let ars = await postuser(users[i], i);
            // 判断是否完成循环
            if(i >= len-1){
                setbtn(0)
                setLoading(false)
            }
            if (ars.message && ars.message == '注册成功') {
                setNum(num => num + 1)
                let datas = [...users];
                datas[i].state = 1;
                setdataSource(datas)
            } else {
                setbtn(1)
                setLoading(false)
                let datas = [...users];
                datas[i].state = 2;
                datas[i].desc = '创建失败:' + ars.response.data.message;
                setdataSource(datas)
                message.error(`账号 [${datas[i].userName}] 创建失败`)
                break;
            }
        }
    }
    // 设置生成账号
    const setAccount = async (values) => {
        setLoading(true)
        let users = await setForuser(values);
        setdataSource(users)
        let len = users.length;
        for (let i = 0; i < users.length; i++) {
            let ars = await postuser(users[i], i);
            // 判断是否完成循环
            if(i >= len-1){
                setbtn(0)
                setLoading(false)
            }
            if (ars.message && ars.message == '注册成功') {
                setNum(num => num + 1)
                let datas = [...users];
                datas[i].state = 1;
                setdataSource(datas)
            } else {
                setbtn(1)
                setLoading(false)
                let datas = [...users];
                datas[i].state = 2;
                datas[i].desc = '创建失败:' + ars.response.data.message;
                setdataSource(datas)
                message.error(`账号: ${datas[i].userName} 创建失败`)
                break;
            }
        }
    }
    // 提交
    const postuser = async (arr, idx) => {
        return await new Promise((resolve, reject) => {
            let ar = JSON.parse(JSON.stringify(arr));
            delete ar.state
            delete ar.desc
            instance.post('/api/v1/api_manage/users/sub', ar)
                .then(res => {
                    resolve(res)
                }).catch((err) => {
                    resolve(err)
                })
        })
    }
    // 生成账号
    const setForuser = (values) => {
        let arr = [];
        for (let i = values.astart; i <= values.end; i++) {
            let name = `${values.username}${setSize(values.size, i)}`;
            arr.push({
                userName: name,
                password: name,
                token: `tk${name}`,
                amount: values.price,
                state: 0,
                desc: ''
            })
        }
        return arr
    }
    // 设置账号位数
    const setSize = (size, val) => {
        if (val.toString().length < size) {
            let v = '0';
            let s = size - val.toString().length;
            return v.repeat(s) + val
        }
        return val
    }
    return (
        <div className='boxFlex' style={{ background: '#fff', padding: '20px', boxSizing: 'border-box' }}>
            <Form
                name="basic"
                style={{
                    maxWidth: 600,
                }}
                initialValues={{
                    remember: true,
                    size: 6,
                    astart: 1,
                    end: 2,
                    price: 1
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                    label="账号前缀"
                    name="username"
                    rules={[
                        {
                            required: true,
                            message: '请输入账号前缀!',
                        },
                    ]}
                >
                    <Input placeholder='请输入账号前缀' />
                </Form.Item>

                <Form.Item
                    label="账号长度"
                    validateTrigger="onBlur"
                    name="size"
                >
                    <InputNumber style={{ width: '100%' }} min={6} max={10000} defaultValue={6} />
                </Form.Item>
                <Form.Item
                    label="起始编号"
                    validateTrigger="onBlur"
                    name="astart"
                >
                    <InputNumber style={{ width: '100%' }} min={1} max={99999} defaultValue={1} />
                </Form.Item>
                <Form.Item
                    label="结束编号"
                    validateTrigger="onBlur"
                    name="end"
                >
                    <InputNumber style={{ width: '100%' }} min={2} max={99999} defaultValue={2} />
                </Form.Item>
                <Form.Item
                    label="账号金额"
                    name="price"
                >
                    <InputNumber style={{ width: '100%' }} min={1} max={9999999} defaultValue={1} />
                </Form.Item>

                <Form.Item
                    wrapperCol={{
                        offset: 3,
                    }}
                >
                    <Space size={30}>
                        <Button type="primary" htmlType="submit" disabled={loading}>
                            {
                                loading ? '等待创建' : '生成账号列表'
                            }
                        </Button>
                        {
                            btn == 1 ?
                                <Button type="primary" onClick={continues}>
                                    继续创建
                                </Button> : ''
                        }
                        <div>创建账号数量：{num} / 总数量：{dataSource.length}</div>
                    </Space>

                </Form.Item>
            </Form>
            <Table dataSource={dataSource} columns={columns} rowKey={record => record.token} />
        </div>
    )
}

export default View;