
import '../../assets/user.css'
import { useEffect, useState } from 'react';
import copy from 'copy-to-clipboard';
import instance from '../../request/api';
import { Col, Row, Divider,message } from 'antd';
function User() {
    let [userInfo, setUserInfo] = useState({
        id: "",
        name: "",
        token: "",
        mobile: "",
        email: "",
        company: "",
        totalConsume: "",
        totalRecharge: "",
        balance: "",
        registerTime: ""
    })
    // 推广链接
    const [short_url, setShort_url] = useState('');
    useEffect(() => {
        getUserInfo()
        getShorturl()
    }, [])
    // 获取用户数据
    const getUserInfo = () => {
        instance.get('/api/v1/api_manage/users')
            .then(data => {
                localStorage.setItem('userInfo', JSON.stringify(data))
                setUserInfo(data)
            })
    }
    // 获取推广链接
    const getShorturl = () => {
        instance.post('/api/v1/api_manage/userlink/short_url')
            .then(data => {
                setShort_url(data.value.shortUrl)
            })
    }
    const copeText = () => {
        copy(short_url)
        message.success('复制成功');
    }
    return (
        <div className="userBox">
            <h3>账号信息</h3>
            <Row>
                <Col span={6}>
                    <div className='col_flex'>
                        <h4>用户名：</h4>
                        <span>{userInfo.name}</span>
                    </div>
                </Col>
                <Col span={6}>
                    <div className='col_flex'>
                        <h4>用户ID：</h4>
                        <span>{userInfo.id}</span>
                    </div>
                </Col>
                <Col span={6}>
                    <div className='col_flex'>
                        <h4>Token：</h4>
                        <span>{userInfo.token}</span>
                    </div>
                </Col>
            </Row>
            <Divider />
            <Row>
                <Col span={6}>
                    <div className='col_flex'>
                        <h4>手机号：</h4>
                        <span>{userInfo.mobile}</span>
                    </div>
                </Col>
                <Col span={6}>
                    <div className='col_flex'>
                        <h4>邮箱：</h4>
                        <span>{userInfo.email}</span>
                    </div>
                </Col>
                <Col span={6}>
                    <div className='col_flex'>
                        <h4>所属公司：</h4>
                        <span>{userInfo.company}</span>
                    </div>
                </Col>
            </Row>
            <Divider />
            <Row>
                <Col span={6}>
                    <div className='col_flex'>
                        <h4>总计消费金额（元）：</h4>
                        <span>￥{userInfo.totalConsume}</span>
                    </div>
                </Col>
                <Col span={6}>
                    <div className='col_flex'>
                        <h4>总计充值金额（元）：</h4>
                        <span>￥{userInfo.totalRecharge}</span>
                    </div>
                </Col>
                <Col span={6}>
                    <div className='col_flex'>
                        <h4>当前余额（元）：</h4>
                        <span>￥{userInfo.balance}</span>
                    </div>
                </Col>
            </Row>
            <Divider />
            <Row>
                <Col span={6}>
                    <div className='col_flex'>
                        <h4>注册时间：</h4>
                        <span>{userInfo.registerTime}</span>
                    </div>
                </Col>
                <Col span={12}>
                    <div className='col_flex'>
                        <h4>推广链接：</h4>
                        <span style={{color:'blue',cursor:'pointer'}} onClick={copeText}>{short_url}</span>
                    </div>
                </Col>
            </Row>
            <Divider />
        </div>
    )
}
export default User;