import React, { useState, useRef, useEffect, useCallback } from "react";
import "../../assets/home.css";
import instance from "../../request/api";
import { Button, Table, Spin } from "antd";
import { getPriceRecordPage } from "../../api/priceRecords";

const columns = [
  {
    title: "",
    align: "center",
    dataIndex: "idx",
    key: "idx",
    width: "70px",
  },
  {
    title: "接口名称",
    align: "center",
    dataIndex: "apiName",
    key: "apiName",
  },
  {
    title: "中文名称",
    align: "center",
    dataIndex: "apiZhName",
    key: "apiZhName",
  },
  {
    title: "变动前价格（里）",
    align: "center",
    dataIndex: "apiBeforePrice",
    key: "apiBeforePrice",
  },
  {
    title: "变动后价格（里）",
    align: "center",
    dataIndex: "apiAfterPrice",
    key: "apiAfterPrice",
  },
  {
    title: "变动时间",
    align: "center",
    dataIndex: "createAt",
    key: "createAt",
  },
];

const View = () => {
  // 设置table高度自动
  const tableVal = useRef(null);
  const [loading, setLoading] = useState(false);
  const [tables, setTables] = useState([]);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(20);
  const [total, setTotal] = useState(0);
  const [height, setHeight] = useState(0);
  const getPages = useCallback(async () => {
    setLoading(true);
    let res = await getPriceRecordPage({ page, size });
    setLoading(false);
    let val = [];
    if (res.data.length > 0) {
      for (let i in res.data) {
        let idx = parseInt(i) + 1;
        val.push({ ...res.data[i], idx });
      }
    }
    setTables(val);
    setTotal(res.rowsCount);
  }, [page, size]);
  useEffect(() => {
    getPages();
  }, [getPages]);
  useEffect(() => {
    let val = tableVal.current.offsetHeight;
    setHeight(val - 120);
  }, [tables]);
  // 页码变化时
  const onChangePage = (page) => {
    console.log(page);
    setPage(page);
  };
  // pagesize数量变化时
  const onShowSizeChangePage = (curr, pageSize) => {
    console.log(pageSize);
    setSize(pageSize);
  };
  return (
    <div className="boxFlex" style={{ background: "#fff" }}>
      <div className="tableList1" ref={tableVal} style={{ background: "#fff" }}>
        <Spin spinning={loading}>
          <Table
            columns={columns}
            dataSource={tables}
            rowKey={(record) => record.apiName}
            scroll={{
              scrollToFirstRowOnChange: true,
              y: height,
            }}
            pagination={{
              position: ["bottomCenter"],
              current: page,
              defaultPageSize: size,
              pageSizeOptions: [20, 50, 100, 200],
              total: total,
              onChange: (page) => onChangePage(page),
              onShowSizeChange: (current, size) =>
                onShowSizeChangePage(current, size),
            }}
          />
        </Spin>
      </div>
    </div>
  );
};
export default View;
