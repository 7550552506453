import React, {
  useState,
  useRef,
  useEffect,
  useLayoutEffect,
  useCallback,
} from "react";
import "../../assets/home.css";
import { Button, Table, Spin } from "antd";
import { getApiPage } from "../../api/apis";
import { useMemo } from "react";

const columns = [
  {
    title: "接口名称",
    align: "center",
    dataIndex: "apiName",
    key: "apiName",
  },
  {
    title: "中文名称",
    align: "center",
    dataIndex: "apiZhName",
    key: "apiZhName",
  },
  {
    title: "计费价格（里）",
    align: "center",
    dataIndex: "apiPrice",
    key: "apiPrice",
    render: (_, record) => <span>{record.apiPrice}</span>,
  },
];

const View = () => {
  // 设置table高度自动
  const tableVal = useRef(null);
  const [loading, setLoading] = useState(false);
  const [tables, setTables] = useState([]);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(20);
  const [total, setTotal] = useState(0);
  const [height, setHeight] = useState(0);
  const getApis = useCallback(async () => {
    try {
      setLoading(true);
      let res = await getApiPage({
        size: size,
        page: page,
      });
      setLoading(false);
      console.log(res);
      console.log(res.data);
      setTables(res.data);
      setTotal(res.rowsCount);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, [page, size]);
  useEffect(() => {
    getApis();
  }, [getApis]);
  useEffect(() => {
    let val = tableVal.current.offsetHeight;
    console.log("val", val);
    setHeight(val - 120);
  }, [tables]);

  // 页码变化时
  const onChangePage = (page) => {
    console.log(page);
    setPage(page);
  };
  // pagesize数量变化时
  const onShowSizeChangePage = (curr, pageSize) => {
    console.log(pageSize);
    setSize(pageSize);
  };
  return (
    <div className="boxFlex" style={{ background: "#fff" }}>
      <div className="tableList1" ref={tableVal} style={{ background: "#fff" }}>
        <Spin spinning={loading}>
          <Table
            columns={columns}
            dataSource={tables}
            rowKey={(record) => {
              console.log(record);
              return record.id;
            }}
            scroll={{
              scrollToFirstRowOnChange: true,
              y: height,
            }}
            pagination={{
              position: ["bottomCenter"],
              current: page,
              defaultPageSize: size,
              pageSizeOptions: [20, 50, 100, 200],
              total: 1000,
              onChange: (page) => onChangePage(page),
              onShowSizeChange: (current, size) =>
                onShowSizeChangePage(current, size),
            }}
          />
        </Spin>
      </div>
    </div>
  );
};
export default View;
