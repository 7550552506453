/**验证码 */
import request from "../request/api";

export const sendEmailCaptcha = (params) => {
  return request({
    method: "get",
    url: "/api/v3/captcha",
    params: params,
  });
};
