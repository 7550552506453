import React, { useState, useRef, useEffect, useCallback } from "react";
import "../../assets/home.css";
import instance from "../../request/api";
import { getSettingInfo, updateSettingInfo } from "../../api/setting";
import { Button, Radio, Form, Input, message, Spin } from "antd";

const View = () => {
  let [loading, setLoading] = useState(false);
  // 控制验证
  let [opt, setOpt] = useState(1);
  // 单选
  const onChange = (e) => {
    setOpt(e.target.value);
  };
  // 配置内容
  const [datas, setData] = useState({
    email_Notice: "",
    qw_Hook: "",
    notice_Type: "1",
  });
  const [pageLoading, setPageLoading] = useState(false);

  const getData = useCallback(async () => {
    setPageLoading(false);
    try {
      let res = await getSettingInfo();
      setPageLoading(true);
      console.log(res);
      setData(res.data);
      setOpt(res.notice_Type === "2" ? "2" : "1");
    } catch (err) {
      setPageLoading(true);
      if (err.response) {
        message.error(err.response.data.msg);
      } else {
        message.error(err.message);
      }
    }
  }, []);
  // 获取配置内容
  useEffect(() => {
    getData();
  }, [getData]);
  // 提交成功
  const onFinish = async (values) => {
    try {
      let { email_Notice, qw_Hook, notice_Type } = values;
      let per = {
        email_Notice: email_Notice ? email_Notice : "",
        qw_Hook: qw_Hook ? qw_Hook : "",
        notice_Type: notice_Type ? notice_Type : "",
      };
      console.log(per);

      setLoading(true);

      let res = await updateSettingInfo(per);
      setLoading(false);
      message.success(res.message);
    } catch (err) {
      setLoading(false);
      if (err.response) {
        message.error(err.response.data.msg);
      } else {
        message.error(err.message);
      }
    }
  };

  // 提交失败
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <div className="boxFlex" style={{ background: "#fff" }}>
      {pageLoading ? (
        <div className="balanceBox">
          <Form
            name="basic"
            labelCol={{
              span: 6,
            }}
            style={{
              maxWidth: 600,
            }}
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label="邮箱通知"
              name="email_Notice"
              initialValue={datas.email_Notice}
              rules={[
                {
                  required: opt == 1,
                  type: "email",
                  message: "请输入正确的邮箱地址!",
                },
              ]}
            >
              <Input placeholder="请输入邮箱地址" />
            </Form.Item>

            <Form.Item
              label="企业微信机器人通知"
              name="qw_Hook"
              initialValue={datas.qw_Hook}
              rules={[
                {
                  required: opt == 2,
                  type: "url",
                  message: "请输入正确的hook地址!",
                },
              ]}
            >
              <Input placeholder="请输入hook地址" />
            </Form.Item>
            <Form.Item
              label="设置"
              name="notice_Type"
              initialValue={datas.notice_Type === "2" ? "2" : "1"}
            >
              <Radio.Group name="option" onChange={onChange}>
                <Radio value="1">邮箱通知</Radio>
                <Radio value="2">企业微信机器人通知</Radio>
              </Radio.Group>
            </Form.Item>

            <Form.Item
              wrapperCol={{
                offset: 6,
                span: 16,
              }}
            >
              <Button type="primary" htmlType="submit" loading={loading}>
                保存应用
              </Button>
            </Form.Item>
          </Form>
        </div>
      ) : (
        <Spin spinning={!pageLoading} delay={500}>
          <div style={{ width: "100%", height: "500px" }}></div>
        </Spin>
      )}
    </div>
  );
};

export default View;
