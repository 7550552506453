import { Table, Spin } from "antd";
import { useRef, useEffect, useState } from "react";
// 设置token
const setToken = (text) => {
  let val = text.slice(0, 1);
  let val1 = text.slice(text.length - 1);
  return `${val}******${val1}`;
};
const columns = [
  {
    title: "消费编号",
    dataIndex: "id",
    key: "id",
    align: "center",
    width: "90px",
  },
  {
    title: "Token",
    dataIndex: "token",
    key: "token",
    width: "100px",
    align: "center",
    render: (text) => <>{setToken(text)}</>,
  },
  {
    title: "接口名称",
    dataIndex: "apiName",
    key: "apiName",
    width: "400px",
    align: "center",
  },
  {
    title: "IP地址",
    key: "ipAddress",
    width: "140px",
    dataIndex: "ipAddress",
    align: "center",
  },
  {
    title: "请求状态",
    key: "apiStatus",
    width: "120px",
    dataIndex: "apiStatus",
    align: "center",
    render: (text) => (
      <>
        {text === "1" ? (
          <span style={{ color: "#b7eb8f" }}>成功</span>
        ) : (
          <span style={{ color: "red" }}>失败</span>
        )}
      </>
    ),
  },
  {
    title: "使用时间",
    key: "usageTime",
    width: "180px",
    dataIndex: "usageTime",
    align: "center",
  },
  {
    title: "计费（厘）",
    key: "jiFei",
    width: "120px",
    dataIndex: "jiFei",
    align: "center",
  },
  {
    title: "余额（元）",
    key: "balance",
    width: "120px",
    dataIndex: "balance",
    align: "center",
    render: (text) => (
      <>
        <span>￥{text}</span>
      </>
    ),
  },
];

function TableList(props) {
  // 设置table高度自动
  const tableVal = useRef(null);
  const [height, setHeihgt] = useState(0);
  useEffect(() => {
    setTimeout(() => {
      let val = tableVal.current.offsetHeight;
      setHeihgt(val - 120);
    }, 500);
  }, []);
  // 页码变化时
  const onChangePage = (page) => {
    props.onChildClick(page);
  };
  // pagesize数量变化时
  const onShowSizeChangePage = (curr, pageSize) => {
    props.onChildClickSize(pageSize);
  };
  return (
    <div className="tableList" ref={tableVal}>
      <Spin spinning={props.loading}>
        <Table
          columns={columns}
          dataSource={props.tables.list}
          rowKey={(record) => record.id}
          scroll={{
            scrollToFirstRowOnChange: true,
            y: height,
          }}
          pagination={{
            position: ["bottomCenter"],
            defaultPageSize: 10,
            pageSizeOptions: [10, 20, 50, 100, 200],
            total: props.tables.total,
            onChange: (page) => onChangePage(page),
            onShowSizeChange: (current, size) =>
              onShowSizeChangePage(current, size),
          }}
        />
      </Spin>
    </div>
  );
}
export default TableList;
