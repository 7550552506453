import "../../assets/home.css";
import instance from "../../request/api";
import { useEffect, useState } from "react";
import Search from "../../components/Record/search";
import Tablelist from "../../components/Record/TableList";
import excel from "../../export/libs/excel";
import { getRechargePage } from "../../api/recharge";

// 格式化日期
const setTime = (time) => {
  if (!time) return "";
  let y = time.$y;
  let m = time.$M + 1 < 10 ? "0" + (time.$M + 1) : time.$M + 1;
  let d = time.$D < 10 ? "0" + time.$D : time.$D;
  return `${y}-${m}-${d}`;
};

function Record() {
  // 分页
  let [page, setPage] = useState({
    page: 1,
    size: 20,
  });
  // 搜索数据
  let [search, setSearch] = useState({
    startTime: "",
    endTime: "",
  });
  // 表格加载
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  // 表格数据
  let [tables, setTables] = useState([]);

  // 导出使用
  let page1 = {
    page: 1,
    size: 10000,
  };
  let downExcel = [];

  // 头部搜索
  const handleChildSearch = (val) => {
    if (!val) return;
    let obj = { ...search };
    obj.startTime = val.startTime ? setTime(val.startTime[0]) : "";
    obj.endTime = val.startTime ? setTime(val.startTime[1]) : "";
    setLoading(true);
    setSearch(obj);
    setPage({ page: 1, size: 20 });
  };
  useEffect(() => {
    getRecordTableList();
  }, [search, page]);

  const getRecordTableList = async () => {
    try {
      let postData = {};
      if (search.startTime && search.endTime) {
        postData.startTime = search.startTime;
        postData.endTime = search.endTime;
      }
      let res = await getRechargePage({
        query: page,
        data: postData,
      });
      console.log(res);
      setLoading(false);
      setTables({
        list: res.data,
        total: res.rowsCount,
      });
    } catch (error) {
      setLoading(false);
    }
  };
  // 搜索重置
  const handleReset = () => {
    setPage({ page: 1, size: 20 });
    setSearch({ startTime: "", endTime: "" });
  };
  // table组件接收子组件的参数 page页码
  const handleChildClick = (msg) => {
    let data = { ...page };
    data.page = msg;
    setPage(data);
  };
  // table组件接收子组件的参数 size数量
  const handleChildClickSize = (msg) => {
    let data = { ...page };
    data.size = msg;
    setPage(data);
  };

  // 导出
  const onChildexports = (key) => {
    downExcel = [];
    page1.page = 1;
    let total = tables.total;
    let num = Math.ceil(total / page1.size);
    setLoading1(true);
    handleExcelset(num);
  };
  const handleExcelset = (num) => {
    let page = page1.page;
    console.log("handleExcelset", downExcel);
    if (page <= num) {
      getTableData(num);
    } else {
      const params = {
        title: ["充值编号", "充值日期", "充值金额（元）", "余额（元）"],
        key: ["id", "time", "amount", "balance"],
        data: downExcel, // 数据源
        autoWidth: true, //autoWidth等于true，那么列的宽度会适应那一列最长的值
        filename: "充值记录导出",
      };
      excel.exportArrayToExcel(params);
      setLoading1(false);
    }
  };

  const getTableData = (num) => {
    instance
      .post("/api/v1/api_manage/recharge/pages", {
        page: {
          page: page1.page,
          size: page1.size,
        },
        search,
      })
      .then((res) => {
        let a = downExcel;
        let data = a.concat(res.list);
        downExcel = data;
        page1.page += 1;
        handleExcelset(num);
      })
      .catch(() => {
        setTimeout(() => {
          getTableData(num);
        }, 1500);
      });
  };
  // 导出文件处理
  const handle = (data, type) => {
    if (!data) return;
    let t = type == "pdf" ? "pdf" : "vnd.ms-excel";
    let url = window.URL.createObjectURL(
      new Blob([data], { type: `application/${t}` })
    );
    const a = document.createElement("a");
    a.style.display = "none";
    a.download = setTimes();
    a.href = url;
    a.click();
    if (document.body.contains(a)) {
      document.body.removeChild(a);
    }
  };
  const setTimes = () => {
    let myData = new Date();
    let year = myData.getFullYear();
    let mon = myData.getMonth() + 1;
    let date = myData.getDate();
    let hours = myData.getHours();
    let min = myData.getMinutes();
    let seconds = myData.getSeconds();
    let now = `${year}${mon}${date}${hours}${min}${seconds}`;
    return now;
  };
  return (
    <div className="boxFlex">
      <Search
        onChildSearch={handleChildSearch}
        loading={loading1}
        onReset={handleReset}
        onexports={onChildexports}
      />
      <Tablelist
        tables={tables}
        loading={loading}
        onChildClick={handleChildClick}
        onChildClickSize={handleChildClickSize}
        page={page}
      />
    </div>
  );
}
export default Record;
