/**充值记录 */
import request from "../request/api";

export const getApiBillPage = (data) => {
  return request({
    method: "post",
    url: "/api/v3/apiBill",
    params: data.query,
    data: data.data,
  });
};
