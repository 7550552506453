import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Routers from './Router/index'
import zhCN from 'antd/locale/zh_CN';
import { ConfigProvider } from 'antd';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>
  <ConfigProvider locale={zhCN}>
    <Routers />
  </ConfigProvider>

);
