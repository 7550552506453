/**消费记录 */
import request from "../request/api";

export const getExpensePage = (data, params) => {
  return request({
    method: "post",
    url: "/api/v3/expenseRecord",
    data: data.data,
    params: data.query,
  });
};
