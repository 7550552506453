import { Table, Spin } from "antd";
import { useRef, useEffect, useState } from "react";

const columns = [
  {
    title: "充值编号",
    dataIndex: "id",
    key: "id",
    align: "center",
  },
  {
    title: "充值日期",
    dataIndex: "createAt",
    key: "createAt",
    align: "center",
  },
  {
    title: "充值金额（元）",
    dataIndex: "amount",
    key: "amount",
    align: "center",
    render: (text) => (
      <>
        <span>￥{text}</span>
      </>
    ),
  },
  {
    title: "余额（元）",
    dataIndex: "balance",
    key: "balance",
    align: "center",
    render: (text) => (
      <>
        <span>￥{text}</span>
      </>
    ),
  },
];
function Recordlist(props) {
  // 设置table高度自动
  const tableVal = useRef(null);
  const [height, setHeihgt] = useState(0);
  useEffect(() => {
    let val = tableVal.current.offsetHeight;
    setHeihgt(val - 120);
  }, [props.tables.list]);

  // 页码变化时
  const onChangePage = (page) => {
    props.onChildClick(page);
  };
  // pagesize数量变化时
  const onShowSizeChangePage = (curr, pageSize) => {
    props.onChildClickSize(pageSize);
  };
  return (
    <div className="tableList1" ref={tableVal}>
      <Spin spinning={props.loading}>
        <Table
          columns={columns}
          dataSource={props.tables.list}
          rowKey={(record) => record.id}
          scroll={{
            scrollToFirstRowOnChange: true,
            y: height,
          }}
          pagination={{
            position: ["bottomCenter"],
            current: props.page.page,
            defaultPageSize: props.page.size,
            pageSizeOptions: [20, 50, 100, 200],
            total: props.tables.total,
            onChange: (page) => onChangePage(page),
            onShowSizeChange: (current, size) =>
              onShowSizeChangePage(current, size),
          }}
        />
      </Spin>
    </div>
  );
}
export default Recordlist;
