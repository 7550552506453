import "./index.css";
import React, { useEffect, useState } from "react";
import { Button, Space, Form, Input, message, Modal } from "antd";
import instance from "./request/api";
import { Link, useNavigate } from "react-router-dom";
import { resetPassword } from "./api/user";

const { confirm } = Modal;

const Add_user = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [emails, setEmails] = useState("");
  const [resetToken, setResetToken] = useState("");
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    setEmails(searchParams.get("email"));
    setResetToken(searchParams.get("token"));
  }, []);
  // 确认密码验证
  const validatePws = ({ getFieldValue }) => {
    return {
      validator: (_, value) => {
        if (getFieldValue("password") === value) {
          return Promise.resolve();
        }
        if (!value || value == "") {
          return Promise.reject(new Error("请输入确认密码!"));
        }
        return Promise.reject(new Error("两次输入的密码不一致!"));
      },
    };
  };

  const onFinish = async (values) => {
    let val = { ...values };
    delete val.psw;
    val.resetToken = resetToken;
    setLoading(true);
    try {
      let res = await resetPassword(val);
      setLoading(false);
      confirm({
        title: "提示",
        content: res.message,
        cancelText: "取消",
        okText: "确认",
        onOk() {
          window.location.href = "/login";
        },
        onCancel() {
          window.location.href = "/login";
        },
      });
    } catch (err) {
      setLoading(false);
      console.log("出错了：", err);
      message.error(err.response.data.message);
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <div className="add_user">
      <div className="add_user_box">
        <p className="add_user_title">找回或修改密码</p>
        <Form
          name="basic"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          size="large"
        >
          <Form.Item>{emails}</Form.Item>
          <Form.Item
            name="password"
            validateTrigger="onBlur"
            rules={[
              {
                required: true,
                message: "密码至少6位数",
                pattern: new RegExp(/^[a-zA-Z0-9]{6,30}$/, "g"),
              },
            ]}
          >
            <Input.Password placeholder="请输入密码" />
          </Form.Item>
          <Form.Item
            name="psw"
            validateTrigger="onBlur"
            dependencies={["password"]}
            rules={[validatePws]}
          >
            <Input.Password placeholder="确认密码" />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ width: "100%" }}
              loading={loading}
            >
              修改密码
            </Button>
          </Form.Item>
        </Form>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Link
            to={"/"}
            style={{
              textDecoration: "none",
              color: "#1677ff",
              fontSize: "14px",
            }}
          >
            返回登陆页
          </Link>
        </div>
      </div>
    </div>
  );
};
export default Add_user;
