import "./index.css";
import React, { useEffect, useState } from "react";
import { Button, Space, Form, Input, message, Modal } from "antd";
import instance from "./request/api";
import { Link } from "react-router-dom";
import Logincode from "./components/Login/loginCode";
import { register } from "./api/user";
import { useNavigate } from "react-router-dom";

const { confirm } = Modal;

const Register = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    document.title = "新用户注册";
    // 清空缓存
    window.localStorage.removeItem("email");
    // const searchParams = new URLSearchParams(window.location.search);
  }, []);
  // 保存输入的邮箱
  const getRefForm = (e) => {
    window.localStorage.setItem("email", e);
  };
  // 确认密码验证
  const validatePws = ({ getFieldValue }) => {
    return {
      validator: (_, value) => {
        if (getFieldValue("password") === value) {
          return Promise.resolve();
        }
        if (!value || value == "") {
          return Promise.reject(new Error("请输入确认密码!"));
        }
        return Promise.reject(new Error("两次输入的密码不一致!"));
      },
    };
  };

  const onFinish = async (values) => {
    try {
      let val = { ...values };
      delete val.psw;
      console.log(val);
      setLoading(true);
      let res = await register({
        username: val.email,
        password: val.password,
        code: val.code,
        name: val.name,
      });
      setLoading(false);
      confirm({
        title: "提示",
        content: res.message || "注册成功",
        cancelText: "取消",
        okText: "确认",
        onOk() {
          navigate("/login");
        },
        onCancel() {
          navigate("/login");
        },
      });
    } catch (error) {
      setLoading(false);
      console.log("出错了：", error);
      message.error(error.response.data?.message || "注册失败");
    }

    // instance
    //   .post("/api/v1/sso/account/api_user/register", val)
    //   .then((res) => {
    //     setLoading(false);
    //     confirm({
    //       title: "提示",
    //       content: res.message,
    //       cancelText: "取消",
    //       okText: "确认",
    //       onOk() {
    //         window.location.href = res.value.redirectUrl;
    //       },
    //       onCancel() {
    //         window.location.href = res.value.redirectUrl;
    //       },
    //     });
    //   })
    //   .catch((err) => {
    //
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <div className="add_user">
      <div className="add_user_box">
        <p className="add_user_title">用户注册</p>
        <Form
          name="basic"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          size="large"
        >
          <Form.Item
            name="email"
            validateTrigger="onBlur"
            rules={[
              { required: true, message: "邮箱格式错误!", type: "email" },
            ]}
          >
            <Input
              placeholder="邮箱号"
              onChange={(e) => getRefForm(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            name="code"
            validateTrigger="onBlur"
            rules={[
              {
                required: true,
                message: "验证码为4位字符",
                pattern: new RegExp(/^[a-zA-Z0-9]{4,4}$/, "g"),
              },
            ]}
          >
            <Space direction="horizontal">
              <Input placeholder="请输入验证码" style={{ width: 255 }} />
              <Logincode />
            </Space>
          </Form.Item>

          <Form.Item
            name="password"
            validateTrigger="onBlur"
            rules={[
              {
                required: true,
                message: "密码至少6位数",
                pattern: new RegExp(/^\S{6,30}$/, "g"),
              },
            ]}
          >
            <Input.Password placeholder="密码" />
          </Form.Item>
          <Form.Item
            name="psw"
            validateTrigger="onBlur"
            dependencies={["password"]}
            rules={[validatePws]}
          >
            <Input.Password placeholder="确认密码" />
          </Form.Item>
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                message: "输入的姓名不正确",
                pattern: new RegExp(/^[\u4e00-\u9fa5]{2,30}$/, "g"),
              },
            ]}
          >
            <Input placeholder="姓名" />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ width: "100%" }}
              loading={loading}
            >
              注册
            </Button>
          </Form.Item>
        </Form>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Link
            to={"/"}
            style={{
              textDecoration: "none",
              color: "#1677ff",
              fontSize: "14px",
            }}
          >
            返回登陆页
          </Link>
        </div>
      </div>
    </div>
  );
};
export default Register;
