import { useState, useEffect } from "react";
import { Button, message } from "antd";
import instance from "../../request/api";
import { sendEmailCaptcha } from "../../api/captcha";

const Login_code = (props) => {
  let timeRef = null; //设置延时器

  const [show, setShow] = useState(false);

  const [code, setCode] = useState(60);

  useEffect(() => {
    if (!show) return;
    if (code !== 0) {
      timeRef = setTimeout(() => {
        setCode(code - 1);
      }, 1000);
    } else {
      setShow(false);
      setCode(59);
    }
    return () => {
      clearTimeout(timeRef);
    };
  }, [code]);

  // 发送验证码
  const showCode = async () => {
    let ts = window.localStorage.getItem("email");
    if (!/^([a-zA-Z0-9_\.-]+)@([\da-zA-Z\.-]+)\.([a-zA-Z\.]{2,6})$/.test(ts)) {
      message.error("邮箱格式错误");
      return false;
    }
    try {
      let res = await sendEmailCaptcha({
        mail: ts,
      });
      message.success(res.message);
      setShow(true);
      setCode(59);
    } catch (error) {
      message.error(error.response.data.message);
      console.log(error);
    }
  };
  return (
    <Button type="primary" onClick={showCode} disabled={show}>
      {show ? `${code}秒后重试` : "获取验证码"}
    </Button>
  );
};
export default Login_code;
