/**充值记录 */
import request from "../request/api";

export const getPriceRecordPage = (data) => {
  return request({
    method: "get",
    url: "/api/v3/priceRecord",
    params: data,
  });
};
