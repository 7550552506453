import React from 'react';
import 'dayjs/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';
import { Button, DatePicker, Form,Dropdown } from 'antd';
const { RangePicker } = DatePicker;

const dateFormat = 'YYYY-MM-DD';


// 头部搜索
function Search(props) {
    const [form] = Form.useForm();
    // 导出为：
    const items = [
        // {
        //     key: '1',
        //     label: (
        //         <div onClick={() => exports('pdf')}>
        //             PDF
        //         </div>
        //     ),
        // },
        {
            key: '2',
            label: (
                <div onClick={() => exports('excel')}>
                    Excel
                </div>
            ),
        },
    ];
    // 导出
    const exports = (key) => {
        console.log(key)
        props.onexports(key)
    }
    // 提交成功
    const onFinish = (values) => {
        console.log('Success:', values);
        if (!values.startTime) return props.onReset();
        props.onChildSearch(values)
    };
    // 提交失败
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    // 重置
    const onReset = () => {
        form.resetFields();
        props.onReset()
    }
    return (
        <div className='top_search' style={{paddingLeft:'20px'}}>
            <Form
                form={form}
                name="basic"
                initialValues={{
                    remember: true,
                }}
                layout='inline'
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                    label="日期"
                    name="startTime"
                >
                    <RangePicker locale={locale} format={dateFormat} />
                </Form.Item>
                <Form.Item
                    wrapperCol={{
                        offset: 8,
                        span: 16,
                    }}
                >
                    <Button htmlType="button" onClick={onReset}>
                        重置
                    </Button>
                </Form.Item>
                <Form.Item
                    wrapperCol={{
                        offset: 8,
                        span: 16,
                    }}
                >
                    <Button type="primary" htmlType="submit">
                        查询
                    </Button>
                </Form.Item>
            </Form>
            <Dropdown
                menu={{
                    items,
                }}
                placement="bottom"
                trigger='click'
                arrow
            >
                <Button type="primary" htmlType="submit" style={{ marginLeft: '20px' }} loading={props.loading}>
                    导出
                </Button>
            </Dropdown>
        </div>
    )
}
export default Search;