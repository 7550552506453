import App from "../App";
import Login from "../login";
import { BrowserRouter, Link, Routes, Route, Navigate } from "react-router-dom";
import Register from "../register";
import Account from "../Account";
import ResetPassword from "../ResetPassword";
function Main() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />}></Route>
        <Route path="/register" element={<Register />}></Route>
        <Route path="/account" element={<Account />}></Route>
        <Route path="/resetPassword" element={<ResetPassword />}></Route>
        <Route path="/*" element={<App />}></Route>
      </Routes>
    </BrowserRouter>
  );
}
export default Main;
