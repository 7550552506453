import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { Button, Form, Input, Checkbox } from "antd";
import { Link } from "react-router-dom";
import zfb from "../../assets/images/5220.jpg";

function loginName(props) {
  // 表单提交成功
  const onFinish = (values) => {
    props.onChildFinish(values);
  };
  // 表单提交失败
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  // 勾选自动登录
  const onChange = (e) => {
    props.onChildCheck(e.target.checked);
  };
  return (
    <Form
      name="basic"
      initialValues={{
        remember: true,
      }}
      style={{ marginTop: "26px" }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Form.Item
        name="username"
        validateTrigger="onBlur"
        rules={[
          {
            required: true,
            message: "请输入账号或邮箱",
          },
        ]}
      >
        <Input
          size="large"
          placeholder="账号或邮箱"
          prefix={<UserOutlined />}
        />
      </Form.Item>

      <Form.Item
        name="password"
        validateTrigger="onBlur"
        rules={[
          {
            required: true,
            message: "请输入密码",
          },
        ]}
      >
        <Input.Password
          placeholder="密码"
          size="large"
          prefix={<LockOutlined />}
        />
      </Form.Item>
      <div className="login_rememberMe">
        <Checkbox onChange={onChange}>自动登录</Checkbox>
        <Link to="/Account">找回或修改密码</Link>
      </div>
      <Form.Item>
        <Button
          type="primary"
          size="large"
          htmlType="submit"
          style={{ width: "100%" }}
          loading={props.loading}
        >
          登录
        </Button>
      </Form.Item>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        {/* <div style={{ display: 'flex', alignItems: 'center' }}>
                    <p>其他登录方式</p>
                    <img style={{ width: '20px', height: '20px', borderRadius: '50%', marginLeft: '10px' }}
                        src={zfb}></img>
                </div> */}
        <Link to={"/register"}>新用户注册</Link>
      </div>
    </Form>
  );
}
export default loginName;
