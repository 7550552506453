
import React, { useState, useRef, useEffect } from 'react';
import '../../assets/home.css'
import instance from '../../request/api';
import { Button, Table, Descriptions, Spin } from 'antd';
import { Link } from 'react-router-dom'

const columns = [
    {
        title: '用户编号',
        align: 'center',
        dataIndex: 'userId',
        key: 'userId'
    },
    {
        title: 'Token',
        align: 'center',
        dataIndex: 'token',
        key: 'token',
    },
    {
        title: '余额(元)',
        align: 'center',
        dataIndex: 'balance',
        key: 'balance',
        render: (_, record) => <span>￥{record.balance}</span>,
    },
    {
        title: '开户时间',
        align: 'center',
        dataIndex: 'registerTime',
        key: 'registerTime',
    },

];

const View = () => {
    // 设置table高度自动
    const tableVal = useRef(null)
    const [loading, setloading] = useState(false)
    const [tables, setTables] = useState([])
    const [page, setPage] = useState(1)
    const [size, setSize] = useState(20)
    const [total, setTotal] = useState(0)
    const [height, setHeihgt] = useState(0)
    useEffect(() => {
        getusers()
        let val = tableVal.current.offsetHeight;
        setHeihgt(val - 120)
    }, [page, size])
    const getusers = () => {
        setloading(true)
        instance.post('/api/v1/api_manage/users/subs', { page, size })
            .then(data => {
                setloading(false)
                setTables(data.list)
                setTotal(data.total)
            }).catch(() => {
                setloading(false)
            })
    }



    // 页码变化时
    const onChangePage = (page) => {
        console.log(page)
        setPage(page)
    }
    // pagesize数量变化时
    const onShowSizeChangePage = (curr, pageSize) => {
        console.log(pageSize)
        setSize(pageSize)
    }
    // 批量导入开户
    const clickbatch = () => {
        console.log('11')
    }
    return (
        <div className='boxFlex'>
            <div style={{ background: '#fff', padding: "20px" }}>
                <Link to={'/sub_user/batch'}>
                    <Button type="primary">
                        批量自助开户
                    </Button>
                </Link>
            </div>
            <div style={{ background: '#fff', marginTop: '10px' }}>
                {/* <Descriptions bordered column={4} layout="vertical" size='small' style={{ width: '200px' }}>
                    <Descriptions.Item label="下级用户数量" >{total}</Descriptions.Item>
                </Descriptions> */}
                <div className="Statistics" style={{ marginTop: '0' }}>
                    <div className="stat_item" style={{ width: '140px' }}>
                        <div className="stat_item_box">
                            <span className="box_span">下级用户数量</span>
                            <span>{total}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className='tableList1' ref={tableVal} style={{ background: '#fff' }}>
                <Spin spinning={loading}>
                    <Table columns={columns} dataSource={tables} rowKey={record => record.userId} scroll={{
                        scrollToFirstRowOnChange: true,
                        y: height
                    }} pagination={{
                        position: ['bottomCenter'],
                        current: page,
                        defaultPageSize: size,
                        pageSizeOptions: [20, 50, 100, 200],
                        total: total,
                        onChange: (page) => onChangePage(page),
                        onShowSizeChange: (current, size) => onShowSizeChangePage(current, size)
                    }} />
                </Spin>
            </div>
        </div>
    )
}
export default View;