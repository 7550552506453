


// 消费数据总览
function Statistics(props) {
    const statics = props.val;
    function Day() {
        return (
            <div className="Statistics">
                <div className="stat_item">
                    <div className="stat_item_box">
                        <span className="box_span">近7日消费总量（元）</span>
                        <span>￥{statics.day.days7Amount}</span>
                    </div>
                </div>
                <div className="stat_item">
                    <div className="stat_item_box">
                        <span className="box_span">昨日消费总量（元）</span>
                        <span>￥{statics.day.yesterdayAmount}</span>
                    </div>
                </div>
                <div className="stat_item">
                    <div className="stat_item_box">
                        <span className="box_span">近7天使用次数（次）</span>
                        <span>{statics.day.days7Use}</span>
                    </div>
                </div>
                <div className="stat_item">
                    <div className="stat_item_box">
                        <span className="box_span">昨日使用次数（次）</span>
                        <span>{statics.day.yesterdayUse}</span>
                    </div>
                </div>
                <div className="stat_item">
                    <div className="stat_item_box">
                        <span className="box_span">余额（元）</span>
                        <span>￥{statics.day.balance}</span>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <>
        <Day/>
        </>

    )
}
export default Statistics;